import { useEffect, useState } from 'react';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';

import { useCollectionEntityData } from '@/hooks/useQuery.ts';
import { useServices } from '@/Services.tsx';
import { useProjectPollsQuery } from '@/services/api/poll';
import { useProjectPromptsQuery } from '@/services/api/project';
import { usePromptTemplatesQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';

export interface FetchPromptTemplateConfig {
  templateTag?: string;
  minimumNumberOfResults?: number;
}

export function useAvailablePromptTemplates(
  projectId: string,
  config?: FetchPromptTemplateConfig,
): PromptTemplate[] | undefined {
  const { pollService, projectService } = useServices();

  // Collection Queries
  const pollsQuery = useProjectPollsQuery(projectId);
  const promptsQuery = useProjectPromptsQuery(projectId);

  // Entity Queries
  const allPolls = useCollectionEntityData(pollsQuery.data, EntityType.POLL, (id) => pollService.getPoll(id));
  const allPrompts = useCollectionEntityData(promptsQuery.data, EntityType.PROMPT, (id) =>
    projectService.getPrompt(id),
  );
  const promptTemplatesQuery = usePromptTemplatesQuery(config?.templateTag);

  // Result state
  const [availablePrompts, setAvailablePrompts] = useState<PromptTemplate[] | undefined>(undefined);

  useEffect(() => {
    // Wait until all queries are loaded
    const promptTemplates = promptTemplatesQuery.data;
    if (promptTemplates == null || allPolls == null || allPrompts == null) {
      return;
    }

    const usedTemplateIds = new Set<string>();

    // Check poll prompts
    allPolls.forEach((poll) => {
      poll?.prompts.forEach((prompt) => {
        if (prompt != null && prompt.template != null) {
          usedTemplateIds.add(prompt.template.id);
        }
      });
    });

    // Check project prompts
    allPrompts.forEach((prompt) => {
      if (prompt != null && prompt.type === PromptType.TEXT && prompt.template != null) {
        usedTemplateIds.add(prompt.template.id);
      }
    });

    // Filter options
    const filteredInitialPollOptions = promptTemplates.filter(({ id }) => !usedTemplateIds.has(id));

    // Use filtered items only if there is enough questions
    if (config?.minimumNumberOfResults == null || filteredInitialPollOptions.length > config.minimumNumberOfResults) {
      setAvailablePrompts(filteredInitialPollOptions);
    } else {
      setAvailablePrompts(promptTemplates);
    }
  }, [
    allPolls,
    allPrompts,
    pollsQuery.data,
    promptTemplatesQuery.data,
    promptsQuery.data,
    config?.minimumNumberOfResults,
  ]);

  return availablePrompts;
}
